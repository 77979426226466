<template>
    <div class="message">
        <articleIndex @changeIndex="changeIndex" bigImg="https://fzygsz2022-1303885703.cos.ap-chengdu.myqcloud.com/yunge-official-website/hangyezixun.png" title="行业资讯" :leftList="leftList">
            <template slot="right">
                <div class="right_wrap">
                    <ul>
                        <li class="article_item" v-for="(item, index) in articleList" :key="index">
                            <img :src="item.annex" class="article_img" style="cursor: pointer" />
                            <!-- 文章 -->
                            <div style="float: right; width: 755px; height: 100%; box-sizing: border-box; padding-left: 10px;">
                                <!-- 标题 -->
                                <div class="article_title" style="cursor: pointer" @click="toDetail(item.id)">
                                    {{ item.title }}
                                </div>
                                <div class="article_detail">
                                    <div :ref="'desc' + index"></div>
                                </div>
                                <div style="display: flex; justify-content: space-between">
                                    <div class="article_time">
                                        {{ item.infoDate }} {{ item.isTop == '1' ? '(置顶)' : '' }}
                                    </div>
                                    <div class="article_time" style="cursor: pointer" @click="toDetail(item.id)">查看详情</div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <!-- <el-pagination style="margin: 20px 0" background layout="prev, pager, next, jumper, total" :total="total" :page-size="5" @current-change="currentChange" :current-page.sync="page"> </el-pagination> -->
                    <div class="paging">
                        <el-pagination style="margin-top: 20px" background :current-page.sync="page" @current-change="currentChange" :page-size="5" layout="prev, pager, next, jumper" :total="total - 0" v-if="showPage"></el-pagination>
                        <span class="totalInfo">
                            <span>/5条，共{{ total }}条 </span>
                        </span>
                    </div>
                </div>
            </template>
        </articleIndex>
    </div>
</template>

<script>
import { get, post } from '../../request/http'
import { getSimpleText } from '../../util/util'
let that
export default {
    data() {
        return {
            articleList: [],
            page: 1,
            leftList: [
                {
                    text: '软件行业',
                },
                {
                    text: '医药行业',
                },
            ],
            active: '',
            page: 1,
            active: 2,
            total: 0,
            showPage: true
        }
    },
    mounted() {
        that = this
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
        this.active = this.$route.query.active || 0
        this.page = Number(this.$route.query.page)
        this.showPage = false
        this.$nextTick(() => {
            this.showPage = true
        })
        this.getArticle()
    },
    methods: {
        routerPush() {
            this.$router.push({
                path: '/index/message',
                query: {
                    active: this.active,
                    page: this.page,
                },
            })
        },
        // 获取数据
        getArticle() {
            that.articleList = []
            post('/information/getInfoList', {
                page: Number(this.page),
                pageNum: 5,
                title: '',
                type: this.active,
            }).then((res) => {
                if (res.code == 200) {
                    that.articleList = res.data
                    this.total = res.sum
                    setTimeout(function () {
                        that.articleList.forEach((item, index) => {
                            that.$refs['desc' + index][0].innerHTML = getSimpleText(item.content)
                        })
                    }, 1)
                } else {
                    that.$message.warning(res.msg)
                }
            })
        },
        currentChange(page) {
            this.page = page
            this.routerPush()
            this.getArticle()
        },
        changeIndex(text, index) {
            this.active = index
            this.page = 1
            this.routerPush()
            this.getArticle()
        },
        toDetail(id) {
            this.$router.push({ path: '/index/message/detail?active=' + this.active + '&id=' + id })
        }
    },
    watch: {
        $route(to) {
            this.active = this.$route.query.active || 0
            this.page = Number(this.$route.query.page) || 1
            this.getArticle()
        }
    }
}
</script>

<style lang="less" scoped>
.message {
    .right_wrap {
        padding-left: 50px;
        .article_item {
            border-bottom: 1px dashed #ccc;
            padding: 30px 0 30px 10px;
            box-sizing: border-box;
            height: 180px;
            .article_img {
                float: left;
                width: 180px;
                height: 100%;
            }
            .article_title {
                color: #323232;
                font-size: 16px;
            }
            .article_detail {
                margin: 15px 0;
                display: -webkit-box;
                word-break: break-all;
                text-overflow: ellipsis;
                overflow: hidden;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                color: #646464;
                font-size: 14px;
                img {
                    display: none;
                }
            }
            .article_time {
                font-size: 12px;
                color: #000000;
                opacity: 0.5;
            }
        }
        .article_item:first-child {
            padding-top: 0;
        }
        .paging {
            margin: 20px 0;
            display: flex;
            align-items: center;
            /deep/ .el-pagination {
                padding: 0;
            }
            > span {
                display: inline-block;
                height: 28px;
                line-height: 29px;
                margin-top: 20px;
                color: #303133;
                font-size: 13px;
            }
        }
    }
}
</style>
